import React, {useState} from 'react';
import { Menubar } from 'primereact/menubar';
import { Button } from "primereact/button";
import {ModalSubscribe} from "./ModalSubscribe";

export default function MenuSup() {
    const [isVisibleForm, setIsVisibleForm] = useState(false);
    const showForm = () => {
        setIsVisibleForm(true);
    }
    // const itemRenderer = (item) => (
    //     <a className="flex align-items-center p-menuitem-link" href="/">
    //         <span className={item.icon} />
    //         <span className="mx-2">{item.label}</span>
    //         {item.badge && <Badge className="ml-auto" value={item.badge} />}
    //         {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    //     </a>
    // );
    const items = [
        // {
        //     label: 'Home',
        //     icon: 'pi pi-home'
        // },
        // {
        //     label: 'Features',
        //     icon: 'pi pi-star'
        // },
        // {
        //     label: 'Projects',
        //     icon: 'pi pi-search',
        //     items: [
        //         {
        //             label: 'Core',
        //             icon: 'pi pi-bolt',
        //             shortcut: '⌘+S',
        //             template: itemRenderer
        //         },
        //         {
        //             label: 'Blocks',
        //             icon: 'pi pi-server',
        //             shortcut: '⌘+B',
        //             template: itemRenderer
        //         },
        //         {
        //             label: 'UI Kit',
        //             icon: 'pi pi-pencil',
        //             shortcut: '⌘+U',
        //             template: itemRenderer
        //         },
        //         {
        //             separator: true
        //         },
        //         {
        //             label: 'Templates',
        //             icon: 'pi pi-palette',
        //             items: [
        //                 {
        //                     label: 'Apollo',
        //                     icon: 'pi pi-palette',
        //                     badge: 2,
        //                     template: itemRenderer
        //                 },
        //                 {
        //                     label: 'Ultima',
        //                     icon: 'pi pi-palette',
        //                     badge: 3,
        //                     template: itemRenderer
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     label: 'Contact',
        //     icon: 'pi pi-envelope',
        //     badge: 3,
        //     template: itemRenderer
        // }
    ];

    const start = <img alt="logo" src="/logo500x250.png" height="90px" className=""></img>;
    const end = (
        <>
            <Button className="btn-open-form btn-large" label="Garantir minha vaga" onClick={showForm} outlined icon="pi pi-angle-right" iconPos="right"/>
            <Button className="btn-open-form btn-small" label="Inscrever" onClick={showForm}  outlined icon="pi pi-angle-right" iconPos="right"/>
        </>
    );

    return (
        <>
            <div className="menu-content"
                style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        right: '0',
                        zIndex: '1000',
                        backgroundColor: 'rgb(18, 18, 20)',
                    }}
            >
                <Menubar model={items} start={start} end={end}
                    style={{
                        backgroundColor: 'rgb(18, 18, 20)',
                        color: 'rgb(196, 196, 204)',
                        border: 'none',
                        borderRadius: '0',
                        height: '95px',
                        borderBottomWidth: '2px',
                        borderBottomColor: 'rgb(196, 196, 204)',
                        width: '100%',
                    }}
                />
            </div>

            <ModalSubscribe visible={isVisibleForm} setVisible={setIsVisibleForm}/>
        </>
    )
}
