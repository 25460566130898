
import React, {useState} from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown"
import PropTypes from "prop-types";
import {useToastContext} from "../hooks/useToast";
import {isEmpty} from "../helpers/functions";
import CryptoJS from 'crypto-js';
import {API_URL, SITE_NAME, TRILHAS_OPTIONS} from "../helpers/constants";
import {InputTextarea} from "primereact/inputtextarea";

ModalSubscribe.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
}

export function ModalSubscribe({
    visible= false,
    setVisible = null,
}) {
    const { showToast } = useToastContext();
    const [isSaving, setIsSaving] = useState(false);

    const [name, setName] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [course, setCourse] = useState(null);
    const [knowledge, setKnowledge] = useState()

    const clearForm = () => {
        setName('');
        setTelefone('');
        setEmail('');
        setCourse(null);
        setKnowledge(null);
    };
    const saveSubscribe = async () => {

        if(isEmpty(name) || isEmpty(telefone) || isEmpty(email)){
            showToast({ detail: "Preencha todos os campos!"}, 'error');
            return;
        }
        setIsSaving(true);

        const data = {
            name: name,
            email: email,
            phone: telefone,
            course: TRILHAS_OPTIONS[course].name ?? '',
            knowledge: knowledge,
        }
        const secretKey = "victal72_cursos_lp#victal72_cursos_lp";
        const hash = CryptoJS.HmacSHA256(JSON.stringify(data), secretKey).toString(CryptoJS.enc.Hex);

        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data, hash: hash })
            });

            setIsSaving(false);

            if (!response.ok) {
                const errorText = await response.text();
                showToast({ detail: `Houve um erro na requisição! ${response.status}: ${errorText}`}, 'error');
                return;
            }

            clearForm();
            setVisible && setVisible(false);
            showToast({ detail: "Dados salvos! Em breve nossa equipe entrará em contato."}, 'success');
        } catch (error) {
            setIsSaving(false);
            showToast({ detail: `Houve um erro na requisição! ${error.message}`}, 'error');
        }
    };
    return (
        <>
            <div className="card flex justify-content-center">
                <Dialog
                    className="dialog-subscribe no-scrollbar"
                    contentClassName=""
                    visible={visible}
                    modal
                    blockScroll={true}
                    onHide={() => {
                        if (!visible) return;
                        setVisible && setVisible(false);
                    }}
                    style={{ width: '50vw', maxHeight: '100vh', overflow: 'auto'}}
                    breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    content={({hide}) => (
                        <div className="dialog-subscribe-content flex flex-column gap-4 bg-gray "
                             style={{borderRadius: '12px'}}>
                            <div className="text-center">
                                <img src="/logo500x250.png" width="140px" alt="Logo"/>
                            </div>
                            <div className="inline-flex flex-column gap-2">
                                <label htmlFor="name" className="text-primary-50 font-semibold">
                                    Nome completo
                                </label>
                                <InputText id="name" label="Nome completo"
                                           className="bg-white-alpha-20 border-none text-primary-50"
                                           value={name}
                                           onChange={(e) => setName(e.target.value)}
                                           type="text"
                                ></InputText>
                            </div>
                            <div className="inline-flex flex-column gap-2">
                                <label htmlFor="telefone" className="text-primary-50 font-semibold">
                                    Telefone/ WhatsApp
                                </label>
                                <InputText id="telefone" label="Telefone/ WhatsApp"
                                           className="bg-white-alpha-20 border-none text-primary-50"
                                           value={telefone}
                                           onChange={(e) => setTelefone(e.target.value)}
                                           type="text"></InputText>
                            </div>
                            <div className="inline-flex flex-column gap-2">
                                <label htmlFor="email" className="text-primary-50 font-semibold">
                                    E-mail
                                </label>
                                <InputText id="email" label="E-mail"
                                           className="bg-white-alpha-20 border-none text-primary-50"
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                           type="email"></InputText>
                            </div>
                            <div className="inline-flex flex-column gap-2">
                                <label htmlFor="email" className="text-primary-50 font-semibold">
                                    Trilha
                                </label>
                                <Dropdown
                                    value={course}
                                    onChange={(e) => setCourse(e?.value)}
                                    options={Object.values(TRILHAS_OPTIONS)}
                                    className="bg-white-alpha-20 border-none text-primary-50"
                                    optionLabel="name"
                                    optionValue="code"
                                />
                            </div>
                            <div className="inline-flex flex-column gap-2">
                                <label htmlFor="email" className="text-primary-50 font-semibold">
                                    Possui algum conhecimento em programação?
                                </label>
                                <InputTextarea autoResize value={knowledge} onChange={(e) => setKnowledge(e.target.value)} rows={4} cols={30} />
                            </div>
                            <div className="flex align-items-center gap-2">
                                <Button label="Desistir da vaga" onClick={(e) => hide(e)} text
                                        severity="danger"
                                        className="btn-large p-3 w-full border-1 border-red-400 hover:bg-white-alpha-10"
                                        icon="pi pi-chevron-left" iconPos="left"
                                ></Button>
                                <Button label="Desistir" onClick={(e) => hide(e)} text
                                        severity="danger"
                                        className="btn-small p-3 w-full border-1 border-red-400 hover:bg-white-alpha-10"
                                        icon="pi pi-chevron-left" iconPos="left"
                                ></Button>
                                <Button label="Reservar" onClick={saveSubscribe} text severity="success"
                                        className="p-3 w-full border-1 border-green-400 hover:bg-white-alpha-10"
                                        icon="pi pi-chevron-right" iconPos="right"
                                        loading={isSaving}
                                ></Button>
                            </div>
                        </div>
                    )}
                ></Dialog>
            </div>
        </>
    )
}
