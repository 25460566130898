import React, {useEffect} from 'react';
import "primereact/resources/themes/lara-light-teal/theme.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
import './App.css';
import {setAppTitle} from "./helpers/functions";
import { ToastProvider } from "./hooks/useToast";
import MenuSup from "./components/MenuSup";
import LPMain from "./components/LPMain";
import CompaniesHiring from "./components/CompaniesHiring";
import LPVideo from "./components/LPVideo";
import Footer from "./components/Footer";
import WhoIsFor from "./components/WhoIsFor";
import CourseContent from "./components/CourseContent";

function App(){
    useEffect(() => {
        setAppTitle('Cursos programação');
    }, []);

  return (
      <>
          <ToastProvider>
            <MenuSup />
            <LPMain />
            <CompaniesHiring />
            <LPVideo/>
            <WhoIsFor/>
            <CourseContent/>
            <Footer/>
          </ToastProvider>

      </>
  );
}

export default App;
