import React from "react";
import {SITE_NAME} from "../helpers/constants";
import { Divider } from 'primereact/divider';

export default function WhoIsFor(){

    return (
        <>
            <div className="bg-dark">
                <div className="content">
                    <div className="bg-gray who-is-for">
                        <div className="text-center">
                            <div className="badge-bg">
                                <span>Para quem é?_</span>
                            </div>
                            <h2>
                                Independente do seu nível a {SITE_NAME} é para você
                            </h2>
                        </div>
                        <div className="grid p-5">
                            <div className="col-12 md:col-6">
                                <div className="topic">
                                    <h2>
                                        Para aprender do zero
                                    </h2>
                                    <p>
                                        Fundamentos da programação web HTML, CSS, Git, banco de dados, back-end e front-end.
                                    </p>
                                </div>
                                <div className="topic">
                                    <h2>
                                        Para se especializar
                                    </h2>
                                    <p>
                                        Trilhas de Web e Mobile com ferramentas e tecnologias alinhadas com o mercado.
                                    </p>
                                </div>
                                <div className="topic">
                                    <h2>
                                        Para se manter atualizado
                                    </h2>
                                    <p>
                                        Conteúdo atualizado a cada aula
                                    </p>
                                </div>

                            </div>
                            <div className="col-12 md:col-6 text-center">
                                <img
                                    src="https://www.rocketseat.com.br/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffor-who.d1141028.jpg&w=640&q=100"
                                    width="80%" alt="Para quem é?"/>
                            </div>
                        </div>

                        <div className="text-center">
                            <div className="badge-bg">
                                <span>Também é para quem?_</span>
                            </div>
                            <div className="grid mt-3">
                                <div className="col-12 sm:col-6 md:col-3 p-3 inline-flex sm:inline-block" style={{alignItems: 'center'}}>
                                    <i className="pi pi-check-circle" style={{color: 'green'}}></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <p>
                                        Quer fazer transição de carreira para a programação
                                    </p>
                                </div>
                                <div className="col-12 sm:col-6 md:col-3 p-3 inline-flex sm:inline-block" style={{alignItems: 'center'}}>
                                    <i className="pi pi-check-circle" style={{color: 'green'}}></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <p>
                                        Quer ganhar melhores salários ou fazer um upgrade de carreira
                                    </p>
                                </div>
                                <div className="col-12 sm:col-6 md:col-3 p-3 inline-flex sm:inline-block" style={{alignItems: 'center'}}>
                                    <i className="pi pi-check-circle" style={{color: 'green'}}></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <p>
                                        Quer conquistar uma vaga internacional
                                    </p>
                                </div>
                                <div className="col-12 sm:col-6 md:col-3 p-3 inline-flex sm:inline-block" style={{alignItems: 'center'}}>
                                    <i className="pi pi-check-circle" style={{color: 'green'}}></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <p>
                                        Quer trabalhar de casa ou remoto
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}