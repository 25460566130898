import React from 'react';
import { Chip } from 'primereact/chip';


export default function LPMain() {

    return (
        <div className="lp-main" style={{marginTop: '95px'}}>
            <div className="content">
                <div className="badge-bg">
                    <span>Hello World_</span>
                </div>
                <h1>
                    Torne-se um profissional <span>de alta demanda</span> no mercado
                </h1>
                <div className="div-explore">
                    <p className="description">
                        Aprenda programação do zero, com aulas práticas e com quem já trabalha no mercado real.
                    </p>
                    <p className="explore">Explore nossas formações</p>
                    <div>
                        <Chip label="Python" image="/languages/python.svg"/>
                        <Chip label="Java/ Kotlin" image="/languages/java.svg"/>
                        <Chip label="PHP" image="/languages/php.svg"/>
                        <Chip label="React JS" image="/languages/react.svg"/>
                        <Chip label="JavaScript" image="/languages/javascript.svg"/>
                        <Chip label="HTML5/ CSS" image="/languages/html.svg"/>
                    </div>
                    <p className="explore">Ferramentas extras que irá aprender</p>
                    <div>
                        <Chip label="GIT" image="/languages/git.svg"/>
                        <Chip label="Documentação" image="/languages/docs.svg"/>
                        <Chip label="deploy" image="/languages/deploy.svg"/>
                        <Chip label="+10" />
                    </div>
                </div>
            </div>
        </div>
    )
}