import React, {useState} from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Avatar } from 'primereact/avatar';
import PropTypes from "prop-types";
import { Divider } from 'primereact/divider';
import {Steps} from "primereact/steps";

Course.propTypes = {
  course: PropTypes.object,
}

export default function Course({
    course= {}
}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const items = course.levels.map(level => {
        return {
            'label': level.name,
            // command: (event) => {
            //     alert('Level: ' + level.name)
            // }
        }
    });

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-4">
                    <div className="badge-bg">
                        <span>O que você vai aprender_</span>
                    </div>
                    <h2>
                        Aulas simples, práticas e diretas ao ponto
                    </h2>
                    <Divider/>
                    <div className="teachers">
                        <p>Ministrado por:</p>
                        {course.teachers.map(teacher => { return (
                                <span className="flex align-items-center gap-2 w-full">
                                    <Avatar
                                        image={teacher.avatar}
                                        shape="circle"/>
                                    <span className="font-bold avatar-prof-description">
                                        {teacher.name}
                                        <p>{teacher.description}</p>
                                    </span>
                                </span>
                            )
                        })}
                    </div>
                </div>
                <div className="col-12 md:col-8">
                    <div className="card">
                        <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                        <div className="mt-5"></div>
                        {
                            course.levels.map((level, idx) => {
                                return (
                                    <>
                                        <Accordion activeIndex={0} style={{display: idx==activeIndex ? "block" : "none"}}>
                                            {level.content.map(content => { return (
                                                    <AccordionTab key={content.title} header={content.title} >
                                                        <p className="m-0">{content.description}</p>
                                                    </AccordionTab>
                                                )
                                            })}
                                        </Accordion>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}