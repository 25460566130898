import React from 'react';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Divider } from 'primereact/divider';

export default function CompaniesHiring(){
    return (
        <>
            <div className="bg-dark">
                <div className="content companies-hiring">
                    <div className="grid">
                        <div className="col-12 md:col-6 text-center md:text-left">
                            <p>Empresas contratando dev agora mesmo</p>
                            <Divider />
                            <div className="carousel">
                                <div className="carousel-track">
                                    <img src="/companies/ifood.svg" alt="Empresa 1"/>
                                    <img src="/companies/nubank.svg" alt="Empresa 2"/>
                                    <img src="/companies/vale.svg" alt="Empresa 3"/>
                                    <img src="/companies/olx.svg" alt="Empresa 4"/>
                                    <img src="/companies/picpay.svg" alt="Empresa 4"/>

                                    <img src="/companies/ifood.svg" alt="Empresa 1"/>
                                    <img src="/companies/nubank.svg" alt="Empresa 2"/>
                                    <img src="/companies/vale.svg" alt="Empresa 3"/>
                                    <img src="/companies/olx.svg" alt="Empresa 4"/>
                                    <img src="/companies/picpay.svg" alt="Empresa 4"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 grid" style={{display: "flex", alignItems: "center"}}>
                            <div className="col-12 md:col-6 text-center md:text-left md:pl-5">
                                <p>Mais de <strong>20 alunos</strong> já garantiram uma vaga</p>
                            </div>
                            <div className="col-12 md:col-6 pl-7">
                                <AvatarGroup>
                                    <Avatar image="companies/1.png" size="large" shape="circle"/>
                                    <Avatar image="companies/2.png" size="large" shape="circle"/>
                                    <Avatar image="companies/3.png" size="large" shape="circle"/>
                                    <Avatar image="companies/4.png" size="large" shape="circle"/>
                                    <Avatar image="companies/5.png" size="large" shape="circle"/>
                                    <Avatar label="+15" shape="circle" size="large"/>
                                </AvatarGroup>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
        </>
    )

}