import React from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import {COURSES} from "../helpers/constants";
import Course from "./sub/Course";
export default function CourseContent() {
    return (
        <>
            <div className="bg-dark">
                <div className="content course-content">
                        <TabView>
                            {
                                COURSES.map((course) => {
                                    return (
                                        <TabPanel header={course.title} leftIcon={course.icon}>
                                            <p className="m-0">
                                                <Course course={course}/>
                                            </p>
                                        </TabPanel>
                                    )
                                })
                            }
                        </TabView>
                </div>
            </div>
        </>
    )
}