import {Button} from "primereact/button";
import React, {useState} from "react";
import {ModalSubscribe} from "./ModalSubscribe";


export default function LPVideo(){

    const [isVisibleForm, setIsVisibleForm] = useState(false);
    const showForm = () => {
        setIsVisibleForm(true);
    }

    const playVideo = () => {
        const thumbnail = document.querySelector('.video-thumbnail');
        const playButton = document.querySelector('.play-button');
        const video = document.getElementById('lp-video-intro');

        thumbnail.style.display = 'none';
        playButton.style.display = 'none';
        video.style.display = 'block';
        video.play();
    }

    return (

        <div className="lp-video bg-dark">
            <div className="content">
                <div className="video-container">
                    <div className="video-thumbnail">

                    </div>
                    {/*<img src="https://img.youtube.com/vi/tVlcKp3bWH8/maxresdefault.jpg" alt="Video Thumbnail"*/}
                    {/*     className="video-thumbnail"/>*/}

                    <div className="play-content">
                        <button className="play-button" onClick={playVideo}>
                            <span className="play-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                     className="translate-x-0.5 fill-white text-lg text-white lg:text-[2.65rem]">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </span>
                            <span className="play-text">Aperte o play e conheça</span>
                        </button>
                    </div>
                    <video id="lp-video-intro" controls style={{display: "none"}}>
                        <source src="/videos/intro.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="text-center mt-2">
                    <Button className="btn-open-form btn-cto-video" label="Garantir minha vaga" onClick={showForm} outlined icon="pi pi-angle-right" iconPos="right"/>
                </div>
                <ModalSubscribe visible={isVisibleForm} setVisible={setIsVisibleForm}/>
            </div>
        </div>
    )
}